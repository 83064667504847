<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Associar Usuário *
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSave"
        >
          <v-icon class="white--text">
            mdi-content-save
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-card>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="idEmpresa"
              :items="listaempresa"
              item-text="nome"
              item-value="id"
              label="Empresa"
              outlined
              hide-details
              dense
              @change="onSelectEmpresa"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="idUsuario"
              :items="listaUsuario"
              item-text="cdUsuarioDisplay"
              item-value="cdUsuario"
              label="Lista Usuário"
              :disabled="liberarListaUsuario"
              outlined
              hide-details
              dense
              @change="onSelectUsuarioEmpresa"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>
<script>
  import usuarioRep from '../../../../components/services/usuarioRepository'
  import firebase from 'firebase'
  const db = firebase.firestore()
  export default {
    props: {
      listaempresa: {
        type: Array,
        default: () => ([]),
      },
      usuario: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        title: 'Associar',
        idEmpresa: 0,
        idUsuario: '',
        liberarListaUsuario: true,
        listaUsuario: [],
        selecionadousuario: {},
      }
    },
    methods: {
      async getListaUsuario () {
        await usuarioRep.getList().then(response => {
          if (response.data.status === 'sucesso') {
            this.liberarListaUsuario = false
            setTimeout(() => {
              this.listaUsuario = response.data.dados
            }, 500)
          } else if (response.data.status === 'mensagem') {
            this.liberarListaUsuario = true
            this.$swal({
              icon: 'warning',
              text: response.data.messagem,
            })
          } else if (response.data.status === 'error') {
            this.liberarListaUsuario = true
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          }
          this.$store.commit('setSplashScreen', false)
        }).catch(error => {
          this.liberarListaUsuario = true
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: 'Erro ao Carregar o Usuário! \n' + error,
          })
        })
      },
      onSave () {
        if (this.validacao()) {
          this.$store.commit('setSplashScreen', true)
          var dadosUsuario = db.collection('usuarios').doc(this.id)
          return dadosUsuario.update({
            stusuario: 'N',
            uid_empresa: this.idEmpresa,
            cdusuario: this.selecionadousuario.cdUsuario,
            cdvendedor: this.selecionadousuario.cdVendedor,
            tpusuario: this.selecionadousuario.tpUsuario,
          })
            .then(() => {
              this.$store.commit('setSplashScreen', false)
              this.$swal({
                icon: 'success',
                text: 'Usuário Associado com Sucesso!',
              })
              this.$store.commit('SET_EMPRESA', {})
              this.$emit('onSairSalvarAssociar')
            })
            .catch((error) => {
              this.$store.commit('setSplashScreen', false)
              // The document probably doesn't exist.
              // console.error('Error updating document usuario: ', error)
              this.$swal({
                icon: 'error',
                text: 'OCorreu um erro ao Associar, tente novamente mais tarde!\n' + error,
              })
            })
        }
      },
      onSair () {
        this.$emit('onSairAssociar')
      },
      onSelectEmpresa () {
        this.liberarListaUsuario = true
        this.$store.commit('setSplashScreen', true)
        var retorno = this.listaempresa.filter((item) => {
          return item.id === this.idEmpresa
        })
        this.$store.commit('SET_EMPRESA', retorno[0])
        setTimeout(() => {
          this.getListaUsuario()
        }, 500)
      },
      onSelectUsuarioEmpresa () {
        var retorno = this.listaUsuario.filter((item) => {
          return item.cdUsuario === this.idUsuario
        })
        this.selecionadousuario = retorno[0]
      },
      validacao () {
        if (this.idEmpresa === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione um Loja!',
          })
          return false
        } else if (this.idUsuario === 0) {
          this.$swal({
            icon: 'warning',
            text: 'Selecione um Usuário!',
          })
          return false
        } else {
          return true
        }
      },
    },
  }
</script>
