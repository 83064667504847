<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Lista de Usuário
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaUsuario"
        :search="search"
      >
        <template v-slot:item.stusuario="{ item }">
          <v-icon
            v-if="item.stusuario === 'N'"
            color="green"
            class="ml-3"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            color="light-blue darken-4"
            class="ml-3"
          >
            mdi-swap-horizontal-bold
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <!--<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  color="blue"
                  @click="onEditar(item)"
                  v-on="on"
                >
                  mdi-pencil-outline
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>-->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical {{ item.stusuario }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="item.stusuario === 'L'">
                <v-list-item-title>
                  <v-row
                    align="center"
                  >
                    <v-btn
                      text
                      @click="onAssociarUsuario(item.id)"
                    >
                      <v-icon
                        left
                        color="light-blue darken-4"
                      >
                        mdi-swap-horizontal-bold
                      </v-icon>
                      Associar
                    </v-btn>
                  </v-row>
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-title>
                  <v-row
                    align="center"
                  >
                    <v-btn
                      text
                    >
                      <v-icon
                        left
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                      Excluir
                    </v-btn>
                  </v-row>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogAssociar"
      max-width="600"
    >
      <modalAssociar
        v-if="dialogAssociar"
        :id="idUiUsuario"
        :listaempresa="empresa"
        @onSairAssociar="onSairAssociar"
        @onSairSalvarAssociar="onSairSalvarAssociar"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import modalAssociar from '../usuario/componente/modal-associar'
  import firebase from 'firebase'
  const db = firebase.firestore()
  export default {
    components: {
      modalAssociar,
    },
    data () {
      return {
        search: '',
        listaUsuario: [],
        lista: [],
        headers: [
          {
            text: 'Status',
            align: 'start',
            value: 'stusuario',
            width: 100,
          },
          {
            text: 'Loja',
            align: 'start',
            value: 'empresanome',
            width: 150,
          },
          {
            text: 'Usuário',
            align: 'start',
            value: 'nome',
            width: 200,
          },
          {
            text: 'E-mail',
            align: 'start',
            value: 'email',
          },
          {
            text: 'Cód. Vendedor',
            align: 'start',
            value: 'cdvendedor',
            width: 80,
          },
          { text: '', value: 'actions', sortable: false },
        ],
        empresa: [],
        dialogAssociar: false,
        idUiUsuario: '',
      }
    },
    async created () {
      if (this.$store.getters.usuario.email !== 'admin@softfamily.com.br') {
        this.$swal({
          icon: 'warning',
          text: 'Acesso não liberado!',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) this.$router.replace('/v1')
        })
      } else {
        await this.getList()
      }
    },
    methods: {
      async getList () {
        this.$store.commit('setSplashScreen', true)
        await db.collection('usuarios').get().then((resp) => {
          resp.forEach((doc) => {
            var user = doc.data()
            user.id = doc.id
            this.lista.push(user)
          })
        })
        db.collection('empresas').get().then((resp) => {
          resp.forEach((doc) => {
            this.empresa.push({
              id: doc.id,
              nome: doc.data().nome,
              url: doc.data().url,
            })
          })
        })
        for (let index = 0; index < this.lista.length; index++) {
          var dadosempresa = db.collection('empresas').doc(this.lista[index].uid_empresa).get()
          dadosempresa.then((doc) => {
            if (doc.exists) {
              this.lista[index].empresanome = doc.data().nome
            } else {
              if (this.lista[index].stusuario === 'N') { this.lista[index].empresanome = '????' } else { this.lista[index].empresanome = 'LIVRE' }
            }
          })
        }
        setTimeout(() => {
          this.listaUsuario = this.lista.sort(function (a, b) {
            if (a.empresanome > b.empresanome) {
              return 1
            }
            if (a.empresanome < b.empresanome) {
              return -1
            }
            // a must be equal to b
            return 0
          })
          this.empresa = this.empresa.sort(function (a, b) {
            if (a.nome > b.nome) {
              return 1
            }
            if (a.nome < b.nome) {
              return -1
            }
            // a must be equal to b
            return 0
          })
          this.$store.commit('setSplashScreen', false)
        }, 500)
      },
      onAssociarUsuario (id) {
        this.idUiUsuario = id
        this.dialogAssociar = true
      },
      onSairAssociar () {
        this.dialogAssociar = false
      },
      onSairSalvarAssociar () {
        this.dialogAssociar = false
        this.getList()
      },
    },
  }
</script>
